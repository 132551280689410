body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, Heebo;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  direction: rtl;
  font-family: "Assistant", sans-serif;
}

code {
  font-family: "Assistant", sans-serif;
}
#root {
  height: 100%;
}
html {
  height: 100%;
}
